import axios from 'axios';
import streamSaver from 'streamsaver';
import { WritableStream } from 'web-streams-polyfill/ponyfill';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

if (!window.WritableStream) {
    streamSaver.WritableStream = WritableStream;
    window.WritableStream = WritableStream;
}

export default class Repository
{
    static API_URL = window.location.protocol + '//' + window.location.hostname + '/api';
    static REP_URL = `${Repository.API_URL}/repo`;
    static DAT_URL = `${Repository.API_URL}/data`;

    list(){
        const url = `${Repository.REP_URL}/list`;
        return axios.get(url);
    }
    add(data){
        const url = `${Repository.REP_URL}`;
        return axios.post(url, data);
    }
    set(uuid, data){
        const url = `${Repository.REP_URL}/${uuid}`;
        return axios.post(url, data);
    }
    get(uuid){
        const url = `${Repository.REP_URL}/${uuid}`;
        return axios.get(url);
    }
    delete(uuid){
        const url = `${Repository.REP_URL}/${uuid}`;
        return axios.delete(url);
    }
    unlink(uuid, file){
        const url = `${Repository.DAT_URL}/${uuid}/${file}`;
        return axios.delete(url);
    }
    data(uuid){
        const url = `${Repository.DAT_URL}/${uuid}`;
        return axios.get(url);
    }
    upload(repo, file, uuid, callback = () => {}){
        const url = `${Repository.DAT_URL}/${repo}`;
        let formData = new FormData();
        formData.append("file", file);

        return axios.post(url, formData, {
            headers: {"Content-Type": "multipart/form-data"},
            onUploadProgress: data => {
                callback.call(this, uuid, data.loaded, data.total);
            }
        });
    }
    uploadEncrypted(key, repo, file, uuid, callback = () => {}, enc_callback = () => {}){
        const url = `${Repository.DAT_URL}/${repo}`;
        const self = this;

        callback.call(this, uuid, 20, 100, true);
        return file.arrayBuffer()
            .then(array => {
                callback.call(this, uuid, 40, 100, true);
                return self.encrypt(key, array);
            })
            .then(ciphertext => {
                callback.call(this, uuid, 80, 100, true);
                let formData = new FormData();
                formData.append("file", new Blob([ciphertext]), file.name);
                enc_callback();  // notify the frontend that we're now uploading the file i.e. encryption is done

                return axios.post(url, formData, {
                    headers: {"Content-Type": "multipart/form-data"},
                    onUploadProgress: data => {
                        callback.call(this, uuid, data.loaded, data.total, false);
                    }
                });
            });
    }
    async importKey(password) {
        return await window.crypto.subtle.importKey(
            "raw",
            (new TextEncoder()).encode(password),
            "PBKDF2",
            false,
            ["deriveBits", "deriveKey"]
        );
    }
    async deriveKey(salt, password) {
        return await window.crypto.subtle.deriveKey(
            {
                "name": "PBKDF2",
                salt: salt,
                "iterations": 100000,
                "hash": "SHA-256"
            },
            await this.importKey(password),
            { "name": "AES-GCM", "length": 256},
            true,
            [ "encrypt", "decrypt" ]
        );
    }
    async deriveSignKey(salt, password) {
        return await window.crypto.subtle.deriveKey(
            {
                "name": "PBKDF2",
                salt: salt,
                "iterations": 100000,
                "hash": "SHA-256"
            },
            await this.importKey(password),
            { "name": "HMAC", "hash": "SHA-512"},
            true,
            [ "sign", "verify" ]
        );
    }
    async encrypt(password, plaintext) {
        const iv = window.crypto.getRandomValues(new Uint8Array(12));
        const salt = window.crypto.getRandomValues(new Uint8Array(16));
        const key = await this.deriveKey(salt, password);
        const sign = await this.deriveSignKey(salt, password);
        const signature = await crypto.subtle.sign("HMAC", sign, plaintext);  // 64-bytes (not bit)
        const data = await window.crypto.subtle.encrypt(
            {
                name: "AES-GCM",
                iv: iv,
                "tagLength": 128
            },
            key,
            plaintext
        );
        const meta_size = signature.byteLength + salt.byteLength + iv.byteLength;
        const ciphertext = new Uint8Array(meta_size + data.byteLength);
        ciphertext.set(new Uint8Array(signature), 0)
        ciphertext.set(salt, signature.byteLength);
        ciphertext.set(iv, signature.byteLength + salt.byteLength);
        ciphertext.set(new Uint8Array(data), meta_size);
        return ciphertext;
    }
    buf2hex(buffer) { // buffer is an ArrayBuffer
        return [...new Uint8Array(buffer)].map(x => x.toString(16).padStart(2, '0')).join('');
    }
    async decrypt(password, ciphertext) {
        const salt = ciphertext.slice(64, 80);
        const key = await this.deriveKey(salt, password);
        const iv = ciphertext.slice(80, 92);
        const data = ciphertext.slice(92);
        const plaintext = await window.crypto.subtle.decrypt(
            {
                name: "AES-GCM",
                iv: iv,
                "tagLength": 128
            },
            key,
            data
        );
        const sign = await this.deriveSignKey(salt, password);
        const signature = ciphertext.slice(0, 64);
        if (await crypto.subtle.verify("HMAC", sign, signature, plaintext)) {
            return plaintext;
        }
        throw new DOMException("Invalid file signature");
    }
    update(repo, uuid, data, callback = () => {}){
        const url = `${Repository.DAT_URL}/${repo}/${uuid}`;

        return axios.post(url, data, {
            onUploadProgress: data => {
                callback.call(this, uuid, data.loaded, data.total);
            }
        });
    }
    download(repo, uuid, callback = () => {}) {
        const url = `${Repository.DAT_URL}/${repo}/${uuid}`;

        return fetch(url, {credentials: 'include'})
            .then(response => {
                let file = response.headers.get('content-disposition');
                if (file && file.indexOf('attachment') !== -1) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    let matches = filenameRegex.exec(file);
                    if (matches != null && matches[1]) {
                        file = matches[1].replace(/(^['"]+)|(['"]+$)/g, '');
                    }
                }
                let bytesRead = 0;
                const filename = file;
                const reader = response.body.getReader();
                const totalSize = parseInt(response.headers.get('content-length'));
                return new Response(new ReadableStream({
                    start(controller) {
                        return pump();
                        function pump() {
                            return reader.read().then(({ done, value }) => {
                                // When no more data needs to be consumed, close the stream
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                bytesRead += value.length;
                                // Enqueue the next data chunk into our target stream
                                controller.enqueue(value);
                                callback.call(this, uuid, bytesRead, totalSize);
                                return pump();
                            });
                        }
                    }
                })).blob()
                    .then(blob => URL.createObjectURL(blob))
                    .then(url => {
                        // Create a link element
                        const link = document.createElement("a");

                        // Set link's href to point to the Blob URL
                        link.href = url;
                        link.download = filename;
                        link.style.display = 'none';

                        // Append link to the body
                        document.body.appendChild(link);

                        // Dispatch click event on the link
                        // This is necessary as link.click() does not work on the latest firefox
                        link.dispatchEvent(
                            new MouseEvent('click', {
                                bubbles: true,
                                cancelable: true,
                                view: window
                            })
                        );

                        // Remove link from body
                        document.body.removeChild(link);
                    });
            });
    }
    downloadEncrypted(key, repo, uuid, callback = () => {}, enc_callback = () => {}) {
        const url = `${Repository.DAT_URL}/${repo}/${uuid}`;
        const self = this;

        return fetch(url, {credentials: 'include'})
            .then(response => {
                let file = response.headers.get('content-disposition');
                if (file && file.indexOf('attachment') !== -1) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    let matches = filenameRegex.exec(file);
                    if (matches != null && matches[1]) {
                        file = matches[1].replace(/(^['"]+)|(['"]+$)/g, '');
                    }
                }
                let bytesRead = 0;
                const filename = file;
                const reader = response.body.getReader()
                const totalSize = parseInt(response.headers.get('content-length'));
                return new Response(new ReadableStream({
                    start(controller) {
                        return pump();
                        function pump() {
                            return reader.read().then(({ done, value }) => {
                                // When no more data needs to be consumed, close the stream
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                bytesRead += value.length;
                                // Enqueue the next data chunk into our target stream
                                controller.enqueue(value);
                                callback.call(this, uuid, bytesRead, totalSize, true);
                                return pump();
                            });
                        }
                    }
                })).blob()
                    .then(blob => {
                        enc_callback();
                        callback.call(this, uuid, 20, 100, true);
                        return blob.arrayBuffer();
                    })
                    .then(array => {
                        callback.call(this, uuid, 40, 100, true);
                        return self.decrypt(key, array);
                    })
                    .then(blob => {
                        callback.call(this, uuid, 80, 100, true);
                        return URL.createObjectURL(new Blob([blob]))
                    })
                    .then(url => {
                        callback.call(this, uuid, 90, 100, true);
                        // Create a link element
                        const link = document.createElement("a");

                        // Set link's href to point to the Blob URL
                        link.href = url;
                        link.download = filename;
                        link.style.display = 'none';

                        // Append link to the body
                        document.body.appendChild(link);

                        // Dispatch click event on the link
                        // This is necessary as link.click() does not work on the latest firefox
                        link.dispatchEvent(
                            new MouseEvent('click', {
                                bubbles: true,
                                cancelable: true,
                                view: window
                            })
                        );

                        // Remove link from body
                        document.body.removeChild(link);
                        callback.call(this, uuid, 100, 100, false);
                    });
            });
    }
    // I do not trust this, it uses shared service workers and pulls .js files directly from git... eww
    stream(repo, uuid, callback = () => {}){
        const url = `${Repository.DAT_URL}/${repo}/${uuid}`;
        // return axios.get(url, {
        //     responseType: 'stream',
        //     onDownloadProgress: data => callback(uuid, data.loaded, data.total)
        // })

        return fetch(url, {credentials: 'include'})
        .then(response => {
            let file = response.headers.get('content-disposition');
            if (file && file.indexOf('attachment') !== -1) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                let matches = filenameRegex.exec(file);
                if (matches != null && matches[1]) {
                    file = matches[1].replace(/(^['"]+)|(['"]+$)/g, '');
                }
            }
            const filename = file;
            const reader = response.body.getReader();
            const totalSize = parseInt(response.headers.get('content-length'));
            const outStream = streamSaver.createWriteStream(filename, {size: totalSize});
            const writer = outStream.getWriter();
            let bytesRead = 0;
            const pump = () => reader.read().then(({ done, value }) => {
                // When no more data needs to be consumed, close the stream
                if (done) {
                    return writer.close();
                }
                // Enqueue the next data chunk into our target stream
                bytesRead += value.length;  // TODO: this doesn't cancel if the user does :(
                writer.write(value).then(
                    () => callback.call(this, uuid, bytesRead, totalSize)
                ).catch(
                    () => callback.call(this, uuid, bytesRead, totalSize)
                );
                return writer.ready.then(pump);
            });
            return pump();
        });
    }
}
