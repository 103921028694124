import React from 'react';
import { Switch } from "react-router-dom";
import axios from 'axios';
import MainHeader from "./components/MainHeader";
import { ToastNotification } from "carbon-components-react";
import SessionContext from "./helpers/SessionContext";
import NavigationHelper from "./helpers/Navigation";
import Account from "./backend/Account";
import Repository from "./backend/Repository";
import {v4 as uuidv4} from 'uuid';

import './App.scss';


axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;


class App extends React.Component {
    constructor(props) {
        super(props);
        this.setProgress = this.setProgress.bind(this);
        this.addToast = this.addToast.bind(this);
        this.addError = this.addError.bind(this);
        this.addInfo = this.addInfo.bind(this);
        this.addWarning = this.addWarning.bind(this);
        this.addSuccess = this.addSuccess.bind(this);
        this.networkFaultHandler = this.networkFaultHandler.bind(this);
        this._networkFaultHandler = this.networkFaultHandler;
        this._addToast = this.addToast;

        this.state = {
            user: {}, toasts: {}, progress: 0,
            setProgress: this.setProgress, addToast: this.addToast,
            networkFaultHandler: this.networkFaultHandler,
            account: new Account(), repository: new Repository()
        };
    }
    setProgress(p) {
        return this.setState({progress: p});
    }
    addToast(kind, title, subtitle, caption, timeout=7500, _uuid = null) {
        // kind: 'error' | 'info' | 'info-square' | 'success' | 'warning' | 'warning-alt'
        const uuid = _uuid != null ? _uuid : uuidv4();
        const toast = {
            kind: kind, title: title, subtitle: subtitle, caption: caption, timeout: timeout
        };
        this.setState(s => {
            let t = {...s.toasts};
            t[uuid] = toast;
            return {toasts: t};
        });
        return uuid;
    }
    addError(message, caption='Please try again', title='Error') {
        return this.addToast('error', title, message, caption)
    }
    addInfo(message, caption='Information', title='Information') {
        return this.addToast('info', title, message, caption)
    }
    addWarning(message, caption='Warning', title='Warning') {
        return this.addToast('warning', title, message, caption)
    }
    addSuccess(message, caption='Operation Successful', title='Success') {
        return this.addToast('success', title, message, caption)
    }
    networkFaultHandler(e) {
        console.log('networkFaultHandler:', e);
        if(e.response && e.response.data && e.response.data.error)
            return this.addError(e.response.data.error);
        else if(e && e.data && e.data.error)
            return this.addError(e.data.error);
        return this.addError('A network error occurred');
    }
    componentWillUnmount() {
        this.networkFaultHandler = () => {};
        this.addError = () => {};
    }
    componentDidMount() {
        this.networkFaultHandler = this._networkFaultHandler;
        this.addToast = this._addToast;

        this.state.account.user().then(d => {
            if (d) this.setState({user: d});
        }).catch(this.networkFaultHandler);
    }
    render() {
        return (
            <SessionContext.Provider value={this.state}>
                <div className="App">
                    <MainHeader/>
                    <Switch>{this.state.user && this.state.user.authorised && NavigationHelper.getRoutes(this.state.user.internal)}</Switch>
                    <div className="xfr-toaster">
                        {Object.values(this.state.toasts).map((t, i) =>
                            <ToastNotification key={i} kind={t.kind} iconDescription="Dismiss" subtitle={t.subtitle}
                                               title={t.title} caption={t.caption} timeout={t.timeout}/>
                        )}
                    </div>
                </div>
            </SessionContext.Provider>
        );
    }
}

export default App;
