import React from 'react';
import { Content } from "carbon-components-react/es/components/UIShell";
import Loading from "../../components/Loading";
import SessionContext from "../../helpers/SessionContext";
import { withRouter } from  'react-router-dom';
import {
    DataTable,
    TableContainer,
    TableToolbar,
    TableToolbarContent,
    TableToolbarSearch,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableHeader,
    TableCell,
    Button,
    ComposedModal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "carbon-components-react";
import Delete32 from "@carbon/icons-react/lib/delete/32";
import Edit32 from "@carbon/icons-react/lib/edit/32";
import ReactDOM from "react-dom";


class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            rows: {},
            deleting: {
                uuid: null, name: ''
            }
        };

        this.headers = [
            {key: 'name', header: 'Name'},
            {key: 'desc', header: 'Description'},
            {key: 'perms', header: 'Permissions'},
            {key: 'expires', header: 'Expires'},
            {key: 'owner', header: 'Owner'},
            {key: 'created', header: 'Created On'},
            {key: 'updated', header: 'Updated On'},
            {key: 'activity', header: 'Latest Activity'},
        ];

        this.handleNavigate = this.handleNavigate.bind(this);
        this.editRepo = this.editRepo.bind(this);
        this.wipeRepo = this.wipeRepo.bind(this);
        this.deleteRepo = this.deleteRepo.bind(this);
    }
    componentDidMount() {
        this.context.repository.list().then(d => {
            if (d && d.data && d.data.repos)
                this.setState(() => {
                    const repos = Object.entries(d.data.repos);
                    const state = {rows: {}, hasActions: false, loading: false};

                    for (const [uuid, repo] of repos) {
                        state.rows[uuid] = {
                            id: uuid, ...repo,
                            expires: new Date(repo.expires).toDateString(),
                            created: new Date(repo.created).toLocaleString(),
                            updated: new Date(repo.updated).toLocaleString(),
                        };
                        if (repo.mine) {
                            state.hasActions = true;
                        }
                    }

                    return state;
                });
        }).catch(this.context.networkFaultHandler);
    }
    handleNavigate(e, href){
        this.props.history.push(href);
        e.preventDefault();
        return false;
    }
    editRepo(e, uuid) {
        e.preventDefault();
        e.stopPropagation();
        this.handleNavigate(e,"/edit/" + uuid);
    }
    wipeRepo(e, uuid) {
        e.preventDefault();
        e.stopPropagation();
        this.setState(s => ({deleting: {
            uuid: uuid, name: s.rows[uuid].name
        }}));
    }
    deleteRepo(e) {
        e.preventDefault();
        e.stopPropagation();

        this.context.repository.delete(this.state.deleting.uuid).then(() => {
            this.context.addToast(
                'success', 'Repository deleted',
                `Successfully deleted "${this.state.deleting.name}"`,
                `Repository UUID: ${this.state.deleting.uuid}`
            );
            this.setState(s => {
                let r = {...s.rows};
                delete r[s.deleting.uuid];
                return {deleting: {uuid: null, name: ''}, rows: r};
            });
        }).catch(e => {
            this.context.networkFaultHandler(e);
            this.setState({deleting: {uuid: null, name: ''}});
        });
    }
    render() {
        return (
            <Content>
                {this.state.loading && <Loading hasLogo={true} modal={true} message={this.state.ticker}/>}
                <div>
                    <DataTable rows={Object.values(this.state.rows)} headers={this.headers} isSortable>
                        {({
                              rows,
                              headers,
                              getHeaderProps,
                              getRowProps,
                              getTableProps,
                              onInputChange,
                          }) => (
                            <TableContainer title="Repositories" description="All repositories you can access and the relevant permissions and summaries for each.">
                                <TableToolbar>
                                    <TableToolbarContent>
                                        <TableToolbarSearch onChange={onInputChange} />
                                    </TableToolbarContent>
                                </TableToolbar>
                                <Table {...getTableProps()}>
                                    <TableHead>
                                        <TableRow>
                                            {headers.map((header) => (
                                                <TableHeader key={header.key} {...getHeaderProps({ header })}>
                                                    {header.header}
                                                </TableHeader>
                                            ))}
                                            {this.state.hasActions && <TableHeader>Actions</TableHeader>}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.length > 0 ? rows.map((row) => (
                                            row.id in this.state.rows && <TableRow
                                                key={row.id} {...getRowProps({ row })} style={{cursor: 'pointer'}}
                                                onClick={(e) => this.handleNavigate(e,"/repo/" + row.id)}
                                            >
                                                {row.cells.map((cell) => (
                                                    <TableCell key={cell.id}>{cell.value}</TableCell>
                                                ))}
                                                {this.state.rows[row.id].mine && <TableCell>
                                                    <div className="xf-file-actions">
                                                        <Button
                                                            kind="ghost"
                                                            className="xf-file-btn"
                                                            renderIcon={Edit32} size="sm"
                                                            iconDescription="Edit Repository"
                                                            hasIconOnly tooltipAlignment="end"
                                                            onClick={e => this.editRepo(e, row.id)}
                                                        />
                                                        <Button
                                                            kind="ghost"
                                                            className="xf-file-btn"
                                                            renderIcon={Delete32} size="sm"
                                                            iconDescription="Delete Repository"
                                                            hasIconOnly tooltipAlignment="end"
                                                            onClick={e => this.wipeRepo(e, row.id)}
                                                        />
                                                    </div>
                                                </TableCell>}
                                            </TableRow>
                                        )) : <TableRow>
                                            <TableCell colSpan={headers.length + (this.state.hasActions ? 1 : 0)}>
                                                You do not currently have access to any repositories.
                                            </TableCell>
                                        </TableRow>}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </DataTable>
                    {this.state.deleting.uuid != null && ReactDOM.createPortal(<ComposedModal
                        open={true} alert='error' danger={true} size='sm' preventCloseOnClickOutside
                        selectorPrimaryFocus='.bx--modal-footer .bx--btn--secondary'
                    >
                        <ModalHeader label="Repository Management" title="Deleting Repository" preventCloseOnClickOutside
                                     buttonOnClick={() => this.setState({deleting: {uuid: null, name: ''}})} />
                        <ModalBody preventCloseOnClickOutside>
                            <p className="bx--modal-content__text">
                                Are you sure you want to <strong>permanently</strong> delete "{this.state.deleting.name}"?
                                <br/><br/>
                                This action is irreversible; all users will lose access to this repository and all associated files, metadata, and encryption keys will be securely erased.
                            </p>
                        </ModalBody>
                        <ModalFooter danger primaryButtonText="Delete Repository" secondaryButtonText="Cancel"
                                     onRequestClose={() => this.setState({deleting: {uuid: null, name: ''}})}
                                     onRequestSubmit={this.deleteRepo} />
                    </ComposedModal>, document.body)}
                </div>
            </Content>
        );
    }
}

Dashboard.contextType = SessionContext;
export default withRouter(Dashboard);