import React, { Component } from 'react';
import {
    Header,
    HeaderName,
    HeaderNavigation,
    HeaderMenuButton,
    HeaderMenuItem,
    HeaderGlobalBar,
    HeaderGlobalAction,
    HeaderPanel,
    SkipToContent,
    Switcher,
    SwitcherItem,
    SwitcherDivider
} from "carbon-components-react/es/components/UIShell";
import IbmSecurity20 from "@carbon/icons-react/lib/ibm-security/20";
import { Link } from "react-router-dom";
import AccountContext from "../../helpers/AccountContext";
import SessionContext from "../../helpers/SessionContext";

import './main-header.scss';
import { HeaderContainer, SideNav, SideNavItems, HeaderSideNavItems } from "carbon-components-react";
import NavigationHelper from "../../helpers/Navigation";


class MainHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            panelActive: false
        };
    }
    render() {
        return (
            <HeaderContainer
                render={({ isSideNavExpanded, onClickSideNavExpand }) => (
                    <Header aria-label="Red Hive Header" className="xfr-main-header">
                        <SessionContext.Consumer>
                            {s => <div className="xfr-progress" style={s.progress > 0 ? {width: `${s.progress}%`} : {display: 'none'}}/>}
                        </SessionContext.Consumer>
                        <SkipToContent />
                        <HeaderMenuButton
                            aria-label="Open menu"
                            onClick={onClickSideNavExpand}
                            isActive={isSideNavExpanded}
                        />
                        <div className="header-xfr-icon">
                            <IbmSecurity20 />
                        </div>
                        <HeaderName element={Link} to="/" prefix="X-Force Red">
                            <span className="xfr-divider">|</span>Sirius
                            <div className="sirius-star">&#10023;</div>
                        </HeaderName>
                        <HeaderNavigation aria-label="RedHive">
                            <SessionContext.Consumer>
                                {s => s.user.authorised ? NavigationHelper.getHeaderMenuItems(s.user.internal) :
                                    <HeaderMenuItem element="a" href={s.account.sso_url()} key="sso-redirect">Login with IBMid</HeaderMenuItem>
                                }
                            </SessionContext.Consumer>
                        </HeaderNavigation>
                        <HeaderGlobalBar>
                            <SessionContext.Consumer>
                                {s => s.user.authorised &&
                                    <HeaderGlobalAction aria-label="User Options" className="xfr-profile-pic" tooltipAlignment="end"
                                                        onClick={() => this.setState(state => ({panelActive: !state.panelActive}))} key="user">
                                        <img src={s.account.photo_url()} alt={'forename' in s.user ? s.user.forename : 'User'}/>
                                    </HeaderGlobalAction>
                                }
                            </SessionContext.Consumer>
                        </HeaderGlobalBar>
                        <SessionContext.Consumer>
                            {s => s.user.authorised &&
                                <HeaderPanel aria-label="Header Panel" expanded={this.state.panelActive} className="xfr-side-panel">
                                    <div className="xfr-hp-inner">
                                        <div className="xfr-user-info">
                                            <div className="xui-head">Logged in as:</div>
                                            <div className="xui-sub">{s.user.forename} {s.user.surname} ({s.user.username})</div>
                                            <div className="xui-sub">{s.user.email}</div>
                                        </div>
                                        <Switcher aria-label="User" className="xfr-switcher">
                                            <SwitcherDivider />
                                            <SwitcherItem aria-label="Logout" href="/api/account/logout">Logout</SwitcherItem>
                                            <SwitcherDivider />
                                        </Switcher>
                                    </div>
                                </HeaderPanel>
                            }
                        </SessionContext.Consumer>
                        <SideNav
                            aria-label="Side navigation"
                            expanded={isSideNavExpanded}
                            isPersistent={false}>
                            <SideNavItems>
                                <HeaderSideNavItems>
                                    <SessionContext.Consumer>
                                        {s => s.user.authorised ? NavigationHelper.getHeaderMenuItems(s.user.internal) :
                                            <HeaderMenuItem element="a" href={s.account.sso_url()} key="sso-redirect">Login with IBMid</HeaderMenuItem>
                                        }
                                    </SessionContext.Consumer>
                                </HeaderSideNavItems>
                            </SideNavItems>
                        </SideNav>
                    </Header>
                )}
            />
        );
    }
}

MainHeader.contextType = AccountContext;
export default MainHeader;
