import React from 'react';
import { Content } from "carbon-components-react/es/components/UIShell";
import './add-repo.scss';
import {
    Form, FormGroup, TextInput, TextArea, DatePicker, DatePickerInput,
    Button, Toggle, ComposedModal, ModalHeader, ModalBody, ModalFooter
} from "carbon-components-react";
import Add32 from "@carbon/icons-react/lib/add/32";
import TrashCan32 from "@carbon/icons-react/lib/trash-can/32";
import {withRouter} from "react-router-dom";
import SessionContext from "../../helpers/SessionContext";
import ReactDOM from "react-dom";


// TODO: lol add some comments will ya ;)
class AddRepo extends React.Component {
    static defaultProps = {
        uuid: false
    };
    static baseState = {
        adding: false,
        external: false,
        perm_email: '',
        email_valid: false,
        perm_read: true,
        perm_edit: false,
        perm_show: false,
        perm_hide: false,
        perm_wipe: false
    };
    static ibmRegex = new RegExp("^[^@]+((@([a-zA-Z0-9-.]+)+\\.ibm)|(@ibm))\\.(([a-zA-Z0-9-.]{2,3})|([a-zA-Z0-9-.]{2}\\.[a-zA-Z0-9-.]{2,3}))$");
    constructor(props) {
        super(props);
        let { uuid } = props;

        if (this.props.match.params.uuid) uuid = this.props.match.params.uuid;

        this.state = {
            done: null,
            uuid: uuid,
            perms: {},
            repo_name: '',
            repo_desc: '',
            repo_date: '',
            ...AddRepo.baseState
        };

        this.reset = this.reset.bind(this);
        this.addRepo = this.addRepo.bind(this);
        this.addPerm = this.addPerm.bind(this);
        this.setEmail = this.setEmail.bind(this);
        this.swapPerm = this.swapPerm.bind(this);
        this.setPerm = this.setPerm.bind(this);
        this.delPerm = this.delPerm.bind(this);
    }
    addRepo(e) {
        e.preventDefault();  // stop the actual form from submitting
        let repository = {
            uuid: this.state.uuid,
            name: this.state.repo_name,
            desc: this.state.repo_desc,
            date: this.state.repo_date,
            perms: this.state.perms
        };
        this.context.repository.add(repository).then(d => {
            if (d && d.data && d.data.success)
                if (d.data.uuid) {
                    this.setState(s => ({uuid: d.data.uuid, done: s.uuid === d.data.uuid}));
                } else this.props.history.push('/');
            else
                this.context.addToast(
                    'error', this.state.uuid ? "Error updating repository" : "Error creating repository",
                    d.data && d.data.error ? d.data.error : 'Unknown',
                    "Please check your repository details and try again"
                );
        }).catch(this.context.networkFaultHandler);
    }
    minDate() {
        let tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        return tomorrow;
    }
    maxDate() {
        let oneYear = new Date();
        oneYear.setFullYear(oneYear.getFullYear() + 1);
        return oneYear;
    }
    reset() {
        this.setState({...AddRepo.baseState});
    }
    addPerm() {
        this.setState(s => ({
            perms: {
                ...s.perms, ...Object.fromEntries([[s.perm_email, {
                    email: s.perm_email, read: s.perm_read, edit: s.perm_edit,
                    show: s.perm_show, hide: s.perm_hide, wipe: s.perm_wipe
                }]])
            },
            ...AddRepo.baseState
        }))
    }
    swapPerm(email, perm) {
        this.setState(s => {
            s.perms[email][perm] = !s.perms[email][perm];
            return {perms: s.perms};
        })
    }
    setPerm(email, perm, val) {
        this.setState(s => {
            s.perms[email][perm] = val;
            return {perms: s.perms};
        })
    }
    delPerm(email) {
        this.setState(s => {
            delete s.perms[email];
            return {perms: s.perms};
        })
    }
    setEmail(e) {
        const email = e.target.value.toLowerCase(),
            valid = email.lastIndexOf('.') > email.lastIndexOf('@') > 0 && e.target.validity.valid;
        this.setState({
            perm_email: email, email_valid: valid,
            external: valid && AddRepo.ibmRegex.exec(email) === null
        });
    }
    componentDidMount() {
        if (!this.state.uuid) return;

        this.context.repository.get(this.state.uuid).then(d => {
            if (d && d.data && d.data.repo)
                this.setState({
                    perms: d.data.repo.perms,
                    repo_name: d.data.repo.name,
                    repo_desc: d.data.repo.desc,
                    repo_date: d.data.repo.expires,
                    ...AddRepo.baseState
                })
        }).catch(this.context.networkFaultHandler);
    }
    render() {
        return (
            <Content>
                <div className="xfr-add-repo bx--data-table-container">
                    <div className="bx--data-table-header">
                        <h4 className="bx--data-table-header__title">{this.state.uuid ? "Update" : "Create"} Repository</h4>
                        <p className="bx--data-table-header__description">
                            {this.state.uuid ? "Update an existing" : "Create a new"} time-limited file storage repository, this should normally
                            be set to expire no more than a few weeks after the end of the engagement.
                            <br/>
                            Once a repository expires it will be permanently deleted, along with all of its contents.
                            <br/><br/>
                            <strong>Please Note: </strong> To discourage the use of this system as
                            permanent storage the maximum age of any repository is <strong>1 year</strong>.
                        </p>
                    </div>
                    <div className="bx--data-table-content">
                        <Form className="add-repo-form" onSubmit={this.addRepo}>
                            <FormGroup legendText="Repository Information">
                                <TextInput id="name" labelText="Repository Name" value={this.state.repo_name} required
                                           onChange={e => this.setState({repo_name: e.target.value})}/>
                                <TextArea id="desc" labelText="Description" value={this.state.repo_desc}
                                          onChange={e => this.setState({repo_desc: e.target.value})}/>
                                <DatePicker
                                    datePickerType="single"
                                    dateFormat="d/m/Y" required
                                    maxDate={this.maxDate().toUTCString()}
                                    minDate={this.minDate().toUTCString()}
                                    onChange={e => this.setState({repo_date: e[0]})}
                                    value={this.state.repo_date}
                                >
                                    <DatePickerInput
                                        placeholder="dd/mm/yyyy"
                                        labelText="Expiry Date"
                                        id="expires"
                                    />
                                </DatePicker>
                            </FormGroup>
                            <FormGroup legendText="Repository Permissions">
                                {!this.state.adding && Object.keys(this.state.perms).length <= 0 ? <div>
                                    Only you have access to this repository, would you like to add additional people?
                                </div> : <div className="xf-perms">
                                    Repository owners have all permissions as standard, user-level permissions can be applied below.
                                {Object.entries(this.state.perms).filter(([email]) => email !== this.context.user.email).map(([email, p], i) =>
                                    <div key={"np-entry-" + i} className="perm-entry">
                                        <div className="perm-eml">
                                            <TextInput
                                                id={"np-email-" + i} type="email" disabled readOnly
                                                labelText="User Email (case-insensitive)" value={email}
                                            />
                                        </div>
                                        <Toggle
                                            className="xf-tog" size="sm" labelA="Denied" labelB="Allowed"
                                            labelText="Read Access" toggled={p.read} id={"np-read-" + i}
                                            onToggle={v => this.setPerm(email, 'read', v)}/>
                                        <Toggle
                                            className="xf-tog" size="sm" labelA="Denied" labelB="Allowed"
                                            labelText="Add Files" toggled={p.edit} id={"np-edit-" + i}
                                            onToggle={v => this.setPerm(email, 'edit', v)}/>
                                        <Toggle
                                            className="xf-tog" size="sm" labelA="Denied" labelB="Allowed"
                                            labelText="Share Repo" toggled={p.show} id={"np-show-" + i}
                                            onToggle={v => this.setPerm(email, 'show', v)}/>
                                        <Toggle
                                            className="xf-tog" size="sm" labelA="Denied" labelB="Allowed"
                                            labelText="Un-share Repo" toggled={p.hide} id={"np-hide-" + i}
                                            onToggle={v => this.setPerm(email, 'hide', v)}/>
                                        <Toggle
                                            className="xf-tog" size="sm" labelA="Denied" labelB="Allowed"
                                            labelText="Delete Files" toggled={p.wipe} id={"np-wipe-" + i}
                                            onToggle={v => this.setPerm(email, 'wipe', v)}/>
                                        <Button
                                            kind="secondary"
                                            className="xf-btn-del"
                                            renderIcon={TrashCan32} size="sm"
                                            iconDescription="Remove"
                                            hasIconOnly tooltipAlignment="end"
                                            onClick={() => this.delPerm(email)}
                                        />
                                    </div>)}
                                </div>}
                                {this.state.adding && <div className="xf-new-perm">
                                    <div className="perm-entry">
                                        <div className="perm-eml">
                                            <TextInput
                                                id="np-email" type="email" onChange={this.setEmail}
                                                labelText="User Email (case-insensitive)" value={this.state.perm_email}
                                                warnText="External users will need an IBMid to access repositories"
                                                invalidText="Please enter a valid email address" warn={this.state.external && this.state.perm_email.length > 0}
                                                invalid={!this.state.email_valid && this.state.perm_email.length > 0}
                                            />
                                        </div>
                                        <Toggle
                                            className="xf-tog" size="sm" labelA="Denied" labelB="Allowed" id="np-read"
                                            defaultToggled={true} labelText="Read Access" toggled={this.state.perm_read}
                                            onToggle={() => this.setState(s => ({perm_read: !s.perm_read}))}/>
                                        <Toggle
                                            className="xf-tog" size="sm" labelA="Denied" labelB="Allowed" id="np-edit"
                                            labelText="Add Files" toggled={this.state.perm_edit}
                                            onToggle={() => this.setState(s => ({perm_edit: !s.perm_edit}))}/>
                                        <Toggle
                                            className="xf-tog" size="sm" labelA="Denied" labelB="Allowed" id="np-show"
                                            labelText="Share Repo" toggled={this.state.perm_show}
                                            onToggle={() => this.setState(s => ({perm_show: !s.perm_show}))}/>
                                        <Toggle
                                            className="xf-tog" size="sm" labelA="Denied" labelB="Allowed" id="np-hide"
                                            labelText="Un-share Repo" toggled={this.state.perm_hide}
                                            onToggle={() => this.setState(s => ({perm_hide: !s.perm_hide}))}/>
                                        <Toggle
                                            className="xf-tog" size="sm" labelA="Denied" labelB="Allowed" id="np-wipe"
                                            labelText="Delete Files" toggled={this.state.perm_wipe}
                                            onToggle={() => this.setState(s => ({perm_wipe: !s.perm_wipe}))}/>
                                        <Button
                                            kind="secondary"
                                            className="xf-btn-del"
                                            renderIcon={TrashCan32} size="sm"
                                            iconDescription="Remove"
                                            hasIconOnly tooltipAlignment="end"
                                            onClick={this.reset}
                                        />
                                        <Button
                                            className="xf-btn-add"
                                            renderIcon={Add32} size="sm"
                                            iconDescription="Add"
                                            hasIconOnly tooltipAlignment="end"
                                            onClick={this.addPerm}
                                            disabled={!this.state.email_valid || !(this.state.perm_read || this.state.perm_edit || this.state.perm_show || this.state.perm_hide || this.state.perm_wipe)}
                                        />
                                    </div>
                                </div>}
                            </FormGroup>
                            <div className="xf-btn-flex">
                                <Button kind="secondary" disabled={this.state.adding} onClick={() => this.setState({adding: true})}>Add Permissions</Button>
                                <Button type="submit" tooltipAlignment="end" disabled={this.state.adding}>{this.state.uuid ? "Update" : "Create"} Repository</Button>
                                {this.state.done !== null && ReactDOM.createPortal(<ComposedModal open={true} size='md'>
                                    <ModalHeader label="Repository Management" title={"Repository " + (this.state.done ? "Updated!" : "Created!")} />
                                    <ModalBody>
                                        <p className="bx--modal-content__text">
                                            Where would you like to go next?
                                        </p>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button
                                            kind="ghost"
                                            onClick={() => this.setState({done: null})}>
                                            Wait! I have more changes to make!
                                        </Button>
                                        <Button
                                            kind="secondary"
                                            onClick={() => this.props.history.push('/')}>
                                            List my repositories
                                        </Button>
                                        <Button
                                            kind="primary"
                                            onClick={() => this.props.history.push(`/repo/${this.state.uuid}`)}>
                                            Continue to repository
                                        </Button>
                                    </ModalFooter>
                                </ComposedModal>, document.body)}
                            </div>
                        </Form>
                    </div>
                </div>
            </Content>
        );
    }
}

AddRepo.contextType = SessionContext;
export default withRouter(AddRepo);