import React from 'react';
import {Content} from "carbon-components-react/es/components/UIShell";
import './view-repo.scss';
import SessionContext from "../../helpers/SessionContext";
import {withRouter} from "react-router-dom";
import {
    Button, ComposedModal,
    DataTable,
    FileUploaderDropContainer,
    FileUploaderItem, ModalBody, ModalFooter, ModalHeader,
    Table,
    TableBatchAction,
    TableBatchActions,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
    TableSelectAll,
    TableSelectRow,
    TableToolbar,
    TableToolbarContent,
    TableToolbarSearch, TextArea, TextInput, Toggle, InlineNotification
} from "carbon-components-react";
import Delete32 from "@carbon/icons-react/lib/delete/32";
import Download32 from "@carbon/icons-react/lib/download/32";
import Edit32 from "@carbon/icons-react/lib/edit/32";
import Add32 from "@carbon/icons-react/lib/add/32";
import TrashCan32 from "@carbon/icons-react/lib/trash-can/32";
import {v4 as uuidv4} from 'uuid';
import ReactDOM from "react-dom";


class ViewRepo extends React.Component {
    static defaultProps = {
        uuid: false,
        batch: false,
    };
    static basePerms = {
        email: '',
        external: true,
        valid: false,
        read: false,
        edit: false,
        show: false,
        hide: false,
        wipe: false
    };
    static baseState = {
        name: '',
        desc: '',
        path: '',
        expires: '',
        owner: '',
        activity: '',
        created: null,
        updated: null,
        perms: {
            read: false,
            edit: false,
            show: false,
            hide: false,
            wipe: false,
            mine: false
        },
        files: {},
        acl: {},
        uploads: {},
        uploading: {'total': 0, 'done': 0},
        downloading: {'total': 0, 'done': 0},
        deleting: {uuid: null, name: ''},
        updating: null,
        updates: {
            name: '', desc: '', error: null, perms: {}
        },
        pending: null,
        existing: true
    };
    // TODO: Move all permissions handling to a single component,
    //       repeating these elements defeats the whole point of react components.
    static ibmRegex = new RegExp("^[^@]+((@([a-zA-Z0-9-.]+)+\\.ibm)|(@ibm))\\.(([a-zA-Z0-9-.]{2,3})|([a-zA-Z0-9-.]{2}\\.[a-zA-Z0-9-.]{2,3}))$");
    constructor(props) {
        super(props);
        let { uuid, batch } = props;

        if (this.props.match.params.uuid) uuid = this.props.match.params.uuid;

        this.state = {
            uuid: uuid,
            batch: batch,
            ...ViewRepo.baseState
        };

        this.headers = [
            {key: 'name', header: 'Name'},
            {key: 'desc', header: 'Description'},
            {key: 'path', header: 'File Name'},
            {key: 'bytes', header: 'File Size'},
            {key: 'access', header: 'Permissions'},
            {key: 'owner', header: 'Owner'},
            {key: 'created', header: 'Created On'},
            {key: 'updated', header: 'Updated On'},
            {key: 'activity', header: 'Latest Activity'},
        ];

        this.updateProgress = this.updateProgress.bind(this);
        this.download = this.download.bind(this);
        this.update = this.update.bind(this);
        this.updateFile = this.updateFile.bind(this);
        this.setPerm = this.setPerm.bind(this);
        this.addPerm = this.addPerm.bind(this);
        this.initPerm = this.initPerm.bind(this);
        this.delPerm = this.delPerm.bind(this);
        this.setEmail = this.setEmail.bind(this);
        this.delete = this.delete.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
        this.addFiles = this.addFiles.bind(this);
        this.delUpload = this.delUpload.bind(this);
    }
    updateProgress(uuid, bytesRead, total, quiet = false, upload = false) {
        this.setState(s => {
            const state = upload ? s.uploading : s.downloading;
            if (uuid in state) {
                if (state[uuid][2]) return;  // already finished, skip
            } else {
                state['total']++;
            }
            state[uuid] = [bytesRead, total, bytesRead >= total];
            if (state[uuid][2]) state['done']++;
            return upload ? {uploading: state} : {downloading: state};
        }, () => {
            let upBytesRead = 0, upBytesSize = 0, dnBytesRead = 0, dnBytesSize = 0;

            for (const [ uuid, entry ] of Object.entries(this.state.uploading)) {
                if (uuid === 'total' || uuid === 'done') continue;
                upBytesRead += entry[0];
                upBytesSize += entry[1];
            }

            for (const [ uuid, entry ] of Object.entries(this.state.downloading)) {
                if (uuid === 'total' || uuid === 'done') continue;
                dnBytesRead += entry[0];
                dnBytesSize += entry[1];
            }

            if (upload) {
                if (this.state.uploading.done >= this.state.uploading.total > 0) {
                    if (!quiet) this.context.addToast(
                        'info',
                        `Upload${this.state.uploading.total > 1 ? 's' : ''} Complete`,
                        `Processing of ${this.state.uploading.total} file${this.state.uploading.total > 1 ? 's' : ''} complete`,
                        `Transferred ${this.formatBytes(upBytesSize)}`
                    );
                    this.setState({
                        uploading: {'total': 0, 'done': 0}
                    });
                }
            } else {
                if (this.state.downloading.done >= this.state.downloading.total > 0) {
                    if (!quiet) this.context.addToast(
                        'info',
                        `Download${this.state.downloading.total > 1 ? 's' : ''} Complete`,
                        `Processing of ${this.state.downloading.total} file${this.state.downloading.total > 1 ? 's' : ''} complete`,
                        `Transferred ${this.formatBytes(dnBytesSize)}`
                    );
                    this.setState({
                        downloading: {'total': 0, 'done': 0}
                    });
                }
            }

            const _bytesRead = upBytesRead + dnBytesRead;
            const _bytesSize = upBytesSize + dnBytesSize;

            if (_bytesRead >= _bytesSize) {  // we disable the progress bar when all downloads/uploads are done
                this.context.setProgress(0);
            } else {
                this.context.setProgress(Math.round((100 * _bytesRead) / _bytesSize));
            }
        });
    }
    download(e, uuid, file) {
        e.preventDefault();  // include this for the download button
        e.stopPropagation();  // stop it bubbling up to the row click handler otherwise we'll download it too
        if (uuid in this.state.downloading) {
            this.context.addToast('warning', 'Warning', 'This file is already downloading', 'Please wait for it to finish');
            return;
        }
        const t_uuid = this.context.addToast('info', 'Download Progress', `Preparing download of "${file}"`, 'Decrypting file...', 0);

        this.context.repository.downloadEncrypted(this.state.uuid,
            this.state.uuid, uuid, this.updateProgress,
            () => this.context.addToast('info', 'Download Progress', `Download of "${file}" nearly ready`, 'Preparing download...', 0, t_uuid)
        ).then(() =>
            this.context.addToast('success', 'Download Progress', `Download of "${file}" ready`, 'Starting download...', 2500, t_uuid)
        ).catch(e => {
            this.context.addToast('success', 'Download Progress', `Download of "${file}" failed`, e.data && e.data.error ? e.data.error : e.code, 7500, t_uuid)
            this.context.networkFaultHandler(e);
        });
    }
    update(e, uuid) {
        e.preventDefault();
        e.stopPropagation();  // stop it bubbling up to the row click handler otherwise we'll download it too
        this.setState(s => (
            {updating: uuid, updates: {
                name: s.files[uuid].name, desc: s.files[uuid].desc, error: null, perms: {...s.files[uuid].acl}
            }}
        ));
    }
    updateFile(e) {
        e.preventDefault();
        e.stopPropagation();  // stop it bubbling up to the row click handler otherwise we'll download it too
        if (this.state.updates.error != null) return; // bail!
        const updated_file = this.state.updates.name;

        this.context.repository.update(
            this.state.uuid, this.state.updating,
            {name: this.state.updates.name, desc: this.state.updates.desc, perms: this.state.updates.perms}
        ).then(r =>
            this.setState(s => {
                if (!r.data || !r.data.success) {
                    return {updates: {...s.updates, error: r.data && r.data.error ? r.data.error : r.code}};
                }
                let u = {
                    updating: null, pending: null, existing: true, files: {...s.files},
                    updates: {name: '', desc: '', error: null, perms: {}}
                };

                let file = {
                    ...u.files[s.updating]
                };
                file.name = s.updates.name;
                file.desc = s.updates.desc;
                file.acl = s.updates.perms;
                u.files[s.updating] = file;

                return u;
            }, () => {
                this.context.addToast('success', 'File Updated', `Metadata for "${updated_file}" updated`, 'Operation Successful')
            })
        ).catch(e => {
            this.setState(s => (
                {updates: {...s.updates, error: e.data && e.data.error ? e.data.error : e.code}}
            ));
            this.context.networkFaultHandler(e);
        });
    }
    setPerm(email, perm, val) {
        this.setState(s => {
            if (email == null) {
                let p = {...s.pending};
                p[perm] = val;
                return {pending: p};
            }
            let u = {...s.updates};
            u.perms[email][perm] = val == null ? !u.perms[email][perm] : val;
            return {updates: u};
        })
    }
    addPerm() {
        this.setState(s => {
            let u = {...s.updates};
            u.perms[s.pending.email] = s.pending;
            return {updates: u, pending: null, existing: s.existing && s.pending.email in s.acl};
        });
    }
    initPerm() {
        this.setState({pending: {...ViewRepo.basePerms}});
    }
    delPerm(email) {
        this.setState(s => {
            if (email == null) return {pending: null};
            let u = {...s.updates};
            delete u.perms[email];
            return {updates: u};
        })
    }
    setEmail(e) {
        const email = e.target.value.toLowerCase(),
            valid = email.lastIndexOf('.') > email.lastIndexOf('@') > 0 && e.target.validity.valid,
            external = valid && ViewRepo.ibmRegex.exec(email) === null;
        this.setState(s => ({pending: {...s.pending,
            email: email, valid: valid,
            external: external
        }}));
    }
    delete(e, uuid) {
        e.preventDefault();
        e.stopPropagation();  // stop it bubbling up to the row click handler otherwise we'll download it too
        this.setState(s => ({deleting: {
            uuid: uuid, name: s.files[uuid].path
        }}));
    }
    deleteFile(e) {
        e.preventDefault();
        e.stopPropagation();

        this.context.repository.unlink(this.state.uuid, this.state.deleting.uuid).then(() => {
            this.context.addToast(
                'success', 'File deleted',
                `Successfully deleted "${this.state.deleting.name}"`,
                `File UUID: ${this.state.deleting.uuid}`
            );
            this.setState(s => {
                let r = {...s.files};
                delete r[s.deleting.uuid];
                return {deleting: {uuid: null, name: ''}, files: r};
            });
        }).catch(e => {
            this.context.networkFaultHandler(e);
            this.setState({deleting: {uuid: null, name: ''}});
        });
    }
    addFiles(evt, { addedFiles }) {
        const t_uuid = this.context.addToast(
            'info', 'Upload Progress',
            `Preparing upload of ${addedFiles.length} file${addedFiles.length > 0 ? 's' : ''}`,
            `Encrypting file${addedFiles.length > 0 ? 's' : ''}...`, 0
        );
        let newFiles = {};
        addedFiles.forEach(f => {
            const uuid = uuidv4();
            newFiles[uuid] = {
                name: f.name, size: f.size, date: f.lastModified, type: f.type, uuid: uuid,
                error: null, message: '', title: `Uploading ${this.formatBytes(f.size)} file`,
                status: 'uploading' // 'uploading' | 'edit' | 'complete'
            };
            this.context.repository.uploadEncrypted(this.state.uuid,
                this.state.uuid, f, uuid,
                (uuid, bytesRead, total, quiet) => this.updateProgress(uuid, bytesRead, total, quiet, true),
                () => this.context.addToast('info', 'Upload Progress', `Upload of "${f.name}" nearly ready`, 'Uploading encrypted data...', 0, t_uuid)
            ).then(r => {
                if (!r.data || !r.data.success)
                    this.context.addToast('error', 'Upload Failed!', `Upload of "${f.name}" failed`, r.data && r.data.error ? r.data.error : r.code, 7500, t_uuid);
                else
                    this.context.addToast('success', 'Upload Successful', `Upload of "${f.name}" complete`, 'Secured with: AES-128-GCM', 2500, t_uuid);
                this.setState(s => {
                    let u = {uploads: {...s.uploads}, files: {...s.files}};
                    if (!r.data || !r.data.success) {
                        u.uploads[uuid].status = 'edit';
                        u.uploads[uuid].error = 'Upload Failed';
                        u.uploads[uuid].message = r.data && r.data.error ? r.data.error : r.code;
                    } else {
                        u.uploads[uuid].status = 'complete';
                        u.uploads[uuid].title = 'Uploaded successfully';
                    }
                    if (r.data && r.data.file) {
                        let file = {
                            ...r.data.file
                        };
                        file.id = file.uuid;
                        file.created = new Date(file.created).toLocaleString();
                        file.updated = new Date(file.updated).toLocaleString();
                        file.bytes = this.formatBytes(file.size);
                        u.files[r.data.file.uuid] = file;
                    }
                    return u;
                })
            }).catch(e => {
                this.context.addToast('error', 'Upload Failed!', `Upload of "${f.name}" failed`, e.data && e.data.error ? e.data.error : e.code, 7500, t_uuid);
                this.setState(s => {
                    let u = {...s.uploads};
                    u[uuid].status = 'edit';
                    u[uuid].error = 'Upload Failed';
                    u[uuid].message = e.data && e.data.error ? e.data.error : e.code;
                    return {uploads: u};
                });
                this.context.networkFaultHandler(e);
            })
        });
        this.setState(s => ({uploads: {...s.uploads, ...newFiles}}));
    }
    delUpload(uuid) {
        this.setState(s => {
            let u = {...s.uploads};
            delete u[uuid];
            return {uploads: u};
        });
    }
    componentDidMount() {
        if (!this.state.uuid) return this.props.history.push('/');

        this.context.repository.data(this.state.uuid).then(d => {
            if (d && d.data && d.data.repo)
                this.setState({
                    name: d.data.repo.name,
                    desc: d.data.repo.desc,
                    path: d.data.repo.path,
                    expires: d.data.repo.expires,
                    owner: d.data.repo.owner,
                    created: d.data.repo.created,
                    updated: d.data.repo.updated,
                    activity: d.data.repo.activity,
                    perms: d.data.repo.perms,
                    files: Object.fromEntries(Object.entries(d.data.repo.files).map(e => {
                        let file = e[1];
                        file.id = e[0];
                        file.uuid = e[0];
                        file.created =  new Date(file.created).toLocaleString();
                        file.updated = new Date(file.updated).toLocaleString();
                        file.bytes = this.formatBytes(file.size);
                        return [e[0], file];
                    })),
                    acl: d.data.repo.acl,
                })
        }).catch(this.context.networkFaultHandler);
    }
    render() {
        return (
            <Content>
                <div className="xfr-view-repo">
                    <DataTable rows={Object.values(this.state.files)} headers={this.headers} isSortable>
                        {({
                              rows,
                              headers,
                              getHeaderProps,
                              getRowProps,
                              getSelectionProps,
                              getToolbarProps,
                              getBatchActionProps,
                              onInputChange,
                              selectedRows,
                              getTableProps,
                              getTableContainerProps,
                          }) => (
                            <div className="bx--data-table-container">
                                <div className="xf-files-header">
                                    <div className="bx--data-table-header">
                                        <div className="xf-title-wrap">
                                            <h4 className="bx--data-table-header__title">
                                                {this.state.name}
                                            </h4>
                                            {this.state.perms.mine && <div className="xf-own-actions">
                                                <Button
                                                    kind="ghost"
                                                    className="xf-btn-mod"
                                                    renderIcon={Edit32} size="sm"
                                                    iconDescription="Edit Repository"
                                                    hasIconOnly tooltipAlignment="start"
                                                    onClick={() => this.props.history.push(`/edit/${this.state.uuid}`)}
                                                />
                                            </div>}
                                        </div>
                                        <p className="bx--data-table-header__description">
                                            {this.state.desc}
                                        </p>
                                    </div>
                                    {this.state.perms.edit && <div className="xf-add-files">
                                        <div className="xf-added-files">
                                            {Object.values(this.state.uploads).map(u =>
                                                <FileUploaderItem
                                                    key={u.uuid} size="small" name={u.name} uuid={u.uuid}
                                                    status={u.status} errorSubject={u.error} errorBody={u.message.toString()}
                                                    invalid={u.error !== null} iconDescription={u.title}
                                                    onDelete={() => this.delUpload(u.uuid)}
                                                />
                                            )}
                                        </div>
                                        <FileUploaderDropContainer
                                            labelText="Drag and drop files here or click to upload"
                                            tabIndex={0} multiple onAddFiles={this.addFiles}
                                        />
                                    </div>}
                                </div>
                                <TableToolbar {...getToolbarProps()}>
                                    {this.state.batch && <TableBatchActions {...getBatchActionProps()}>
                                        {this.state.perms.wipe && <TableBatchAction
                                            tabIndex={getBatchActionProps().shouldShowBatchActions ? 1 : -1}
                                            renderIcon={Delete32}
                                            onClick={() => {}}>
                                            Delete
                                        </TableBatchAction>}
                                        <TableBatchAction
                                            tabIndex={getBatchActionProps().shouldShowBatchActions ? 2 : -1}
                                            renderIcon={Download32}
                                            onClick={() => {}}>
                                            Download
                                        </TableBatchAction>
                                    </TableBatchActions>}
                                    <TableToolbarContent>
                                        <TableToolbarSearch
                                            persistent placeHolderText="Search repository"
                                            tabIndex={getBatchActionProps().shouldShowBatchActions ? -1 : 1}
                                            onChange={onInputChange}
                                        />
                                    </TableToolbarContent>
                                </TableToolbar>
                                <Table {...getTableProps()}>
                                    <TableHead>
                                        <TableRow>
                                            {this.state.batch && <TableSelectAll {...getSelectionProps()} />}
                                            {headers.map((header) => (
                                                <TableHeader key={header.key} {...getHeaderProps({ header })}>
                                                    {header.header}
                                                </TableHeader>
                                            ))}
                                            <TableHeader>Actions</TableHeader>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.length > 0 ? rows.map((row) => (
                                            row.id in this.state.files && <TableRow
                                                key={row.id} {...getRowProps({ row })} style={{cursor: 'pointer'}}
                                                onClick={e => this.download(e, row.id, this.state.files[row.id].name)}
                                            >
                                                {this.state.batch && <TableSelectRow {...getSelectionProps({ row })} />}
                                                {row.cells.map((cell) => (
                                                    <TableCell key={cell.id}>{cell.value}</TableCell>
                                                ))}
                                                <TableCell>
                                                    <div className="xf-file-actions">
                                                        <Button
                                                            kind="ghost"
                                                            className="xf-file-btn"
                                                            renderIcon={Download32} size="sm"
                                                            iconDescription="Download File"
                                                            hasIconOnly tooltipAlignment="end"
                                                            onClick={e => this.download(e, row.id, this.state.files[row.id].name)}
                                                        />
                                                        {this.state.files[row.id].perms.edit && <Button
                                                            kind="ghost"
                                                            className="xf-file-btn"
                                                            renderIcon={Edit32} size="sm"
                                                            iconDescription="Edit File"
                                                            hasIconOnly tooltipAlignment="end"
                                                            onClick={e => this.update(e, row.id)}
                                                        />}
                                                        {this.state.files[row.id].perms.wipe && <Button
                                                            kind="ghost"
                                                            className="xf-file-btn"
                                                            renderIcon={Delete32} size="sm"
                                                            iconDescription="Delete File"
                                                            hasIconOnly tooltipAlignment="end"
                                                            onClick={e => this.delete(e, row.id)}
                                                        />}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        )) : <TableRow>
                                            <TableCell colSpan={headers.length + (this.state.batch ? 2 : 1)}>
                                                You do not currently have access to any files in this repository or the repository is empty.
                                            </TableCell>
                                        </TableRow>}
                                    </TableBody>
                                </Table>
                            </div>
                        )}
                    </DataTable>
                    {this.state.deleting.uuid != null && ReactDOM.createPortal(<ComposedModal
                        open={true} alert='error' danger={true} size='sm' preventCloseOnClickOutside
                        selectorPrimaryFocus='.bx--modal-footer .bx--btn--secondary'
                    >
                        <ModalHeader label={this.state.name} title="Deleting File" preventCloseOnClickOutside
                                     buttonOnClick={() => this.setState({deleting: {uuid: null, name: ''}})} />
                        <ModalBody preventCloseOnClickOutside>
                            <p className="bx--modal-content__text">
                                Are you sure you want to <strong>permanently</strong> delete "{this.state.deleting.name}"?
                                <br/><br/>
                                This action is irreversible; all users will lose access to this file and all associated data, encryption keys, and metadata will be securely erased.
                            </p>
                        </ModalBody>
                        <ModalFooter danger primaryButtonText="Delete File" secondaryButtonText="Cancel"
                                     onRequestClose={() => this.setState({deleting: {uuid: null, name: ''}})}
                                     onRequestSubmit={this.deleteFile} />
                    </ComposedModal>, document.body)}
                    {this.state.updating != null && ReactDOM.createPortal(<ComposedModal
                        open={true} size='lg' preventCloseOnClickOutside
                    >
                        <ModalHeader label={this.state.name} preventCloseOnClickOutside
                                     title={this.state.files[this.state.updating].path}
                                     buttonOnClick={() => this.setState({updating: null})} />
                        <ModalBody hasForm preventCloseOnClickOutside className="xf-edit-file">
                            <TextInput data-modal-primary-focus labelText="Name" defaultValue={this.state.updates.name} id="xf-file-name"
                                       onChange={e => this.setState(s => ({updates: {...s.updates, name: e.target.value}}))} />
                            <TextArea data-modal-secondary-focus labelText="Description" defaultValue={this.state.updates.desc} id="xf-file-desc"
                                      onChange={e => this.setState(s => ({updates: {...s.updates, desc: e.target.value}}))} rows={2} />
                            {this.state.perms.mine && <div className="xf-edit-perms">
                                <h4>File Permissions</h4>
                                <div className="xf-perms">
                                    Repository owners have all permissions as standard, user-level permissions can be applied below.
                                {Object.entries(this.state.updates.perms).filter(([email]) => email !== this.context.user.email).map(([email, p], i) =>
                                    <div key={"np-entry-" + i} className="perm-entry">
                                        <div className="perm-eml">
                                            <TextInput
                                                id={"np-email-" + i} type="email" disabled readOnly
                                                labelText="User Email (case-insensitive)" value={email}
                                            />
                                        </div>
                                        <Toggle
                                            className="xf-tog" size="sm" labelA="Denied" labelB="Allowed"
                                            labelText="Read Access" toggled={p.read} id={"np-read-" + i}
                                            onToggle={v => this.setPerm(email, 'read', v)}/>
                                        <Toggle
                                            className="xf-tog" size="sm" labelA="Denied" labelB="Allowed"
                                            labelText="Update File" toggled={p.edit} id={"np-edit-" + i}
                                            onToggle={v => this.setPerm(email, 'edit', v)}/>
                                        <Toggle
                                            className="xf-tog" size="sm" labelA="Denied" labelB="Allowed"
                                            labelText="Share File" toggled={p.show} id={"np-show-" + i}
                                            onToggle={v => this.setPerm(email, 'show', v)}/>
                                        <Toggle
                                            className="xf-tog" size="sm" labelA="Denied" labelB="Allowed"
                                            labelText="Un-share File" toggled={p.hide} id={"np-hide-" + i}
                                            onToggle={v => this.setPerm(email, 'hide', v)}/>
                                        <Toggle
                                            className="xf-tog" size="sm" labelA="Denied" labelB="Allowed"
                                            labelText="Delete File" toggled={p.wipe} id={"np-wipe-" + i}
                                            onToggle={v => this.setPerm(email, 'wipe', v)}/>
                                        <Button
                                            kind="secondary"
                                            className="xf-btn-del"
                                            renderIcon={TrashCan32} size="sm"
                                            iconDescription="Remove"
                                            hasIconOnly tooltipAlignment="end"
                                            onClick={() => this.delPerm(email)}
                                        />
                                    </div>)}
                                </div>
                                {this.state.pending != null && <div className="xf-new-perm">
                                    <div className="perm-entry">
                                        <div className="perm-eml">
                                            <TextInput
                                                id="np-email" type="email" onChange={this.setEmail}
                                                labelText="User Email (case-insensitive)" value={this.state.pending.email}
                                                warnText="External users will need an IBMid to access repositories"
                                                warn={this.state.pending.email.length > 0 && this.state.pending.external}
                                                invalidText="Please enter a valid email address"
                                                invalid={!this.state.pending.valid && this.state.pending.email.length > 0}
                                            />
                                        </div>
                                        <Toggle
                                            className="xf-tog" size="sm" labelA="Denied" labelB="Allowed" id="np-read"
                                            defaultToggled={true} labelText="Read Access" toggled={this.state.pending.read}
                                            onToggle={v => this.setPerm(null, 'read', v)}/>
                                        <Toggle
                                            className="xf-tog" size="sm" labelA="Denied" labelB="Allowed" id="np-edit"
                                            labelText="Add Files" toggled={this.state.pending.edit}
                                            onToggle={v => this.setPerm(null, 'edit', v)}/>
                                        <Toggle
                                            className="xf-tog" size="sm" labelA="Denied" labelB="Allowed" id="np-show"
                                            labelText="Share Repo" toggled={this.state.pending.show}
                                            onToggle={v => this.setPerm(null, 'show', v)}/>
                                        <Toggle
                                            className="xf-tog" size="sm" labelA="Denied" labelB="Allowed" id="np-hide"
                                            labelText="Un-share Repo" toggled={this.state.pending.hide}
                                            onToggle={v => this.setPerm(null, 'hide', v)}/>
                                        <Toggle
                                            className="xf-tog" size="sm" labelA="Denied" labelB="Allowed" id="np-wipe"
                                            labelText="Delete Files" toggled={this.state.pending.wipe}
                                            onToggle={v => this.setPerm(null, 'wipe', v)}/>
                                        <Button
                                            kind="secondary"
                                            className="xf-btn-del"
                                            renderIcon={TrashCan32} size="sm"
                                            iconDescription="Remove"
                                            hasIconOnly tooltipAlignment="end"
                                            onClick={() => this.delPerm(null)}
                                        />
                                        <Button
                                            className="xf-btn-add"
                                            renderIcon={Add32} size="sm"
                                            iconDescription="Add"
                                            hasIconOnly tooltipAlignment="end"
                                            onClick={this.addPerm}
                                            disabled={!this.state.pending.valid || !(this.state.pending.read || this.state.pending.edit || this.state.pending.show || this.state.pending.hide || this.state.pending.wipe)}
                                        />
                                    </div>
                                </div>}
                                <div className="xf-perm-add">
                                    <div>
                                        <Button kind='secondary'
                                                className="xf-btn-add"
                                                renderIcon={Add32}
                                                iconDescription="Add"
                                                tooltipAlignment="end"
                                                onClick={this.initPerm}>
                                            Add New Permissions
                                        </Button>
                                    </div>
                                    {!this.state.existing && <InlineNotification
                                        kind="warning-alt" hideCloseButton={true}
                                        subtitle={<span>
                                            New users will be given repository access and file-level permissions for this file and new uploads only.<br/>
                                            If you want a user to have access to all files in the repository add them at a repository-level instead.
                                        </span>}
                                        title="You have new repository users in your file permissions!"
                                    />}
                                </div>
                            </div>}
                        </ModalBody>
                        <ModalFooter primaryButtonText="Update File" secondaryButtonText="Discard Changes"
                                     onRequestClose={() => this.setState({updating: null})}
                                     onRequestSubmit={this.updateFile} />
                    </ComposedModal>, document.body)}
                </div>
            </Content>
        );
    }
    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
}
ViewRepo.contextType = SessionContext;
export default withRouter(ViewRepo);