import React from 'react';
import Repository from "../../backend/Repository";
import Account from "../../backend/Account";


// Create a Context
const SessionContext = React.createContext({
    user: {}, toasts: [], progress: 0, setProgress: () => {},
    addToast: (kind, title, subtitle, caption, timeout=3500, _uuid = null) => {},
    networkFaultHandler: (event) => {},
    repository: new Repository(), account: new Account()
});
// It returns an object with 2 values:
// { Provider, Consumer }

export default SessionContext;