import React from 'react';
import { Route, Link } from "react-router-dom";
import Dashboard from "../../content/Dashboard";
import ViewRepo from "../../content/ViewRepo";
import AddRepo from "../../content/AddRepo";
import { HeaderMenuItem } from "carbon-components-react";


class NavigationHelper {
    static NAVIGATION = {
        'repos': { path: '/', label: 'Repositories', component: Dashboard, routes: [], hidden: false, internal: false },
        'view': { path: '/repo', label: 'View Repository', component: ViewRepo, routes: ['/:uuid'], hidden: true, internal: false },
        'edit': { path: '/edit', label: 'Edit Repository', component: AddRepo, routes: ['/:uuid'], hidden: true, internal: true },
        'create': { path: '/create', label: 'New Repository', component: AddRepo, routes: [], hidden: false, internal: true },
    }
    static getRoutes(internal = false) {
        let routes = [];
        Object.values(NavigationHelper.NAVIGATION).filter(p => internal || !p.internal).forEach(p => {
            routes.push({...p, exact: true});
            p.routes.forEach(r => routes.push({...p, path: p.path + r, exact: false}));
        });

        return routes.map(r => <Route exact={r.exact} path={r.path} key={r.path} component={r.component} />);
    }
    static getHeaderMenuItems(internal = false) {
        return Object.values(NavigationHelper.NAVIGATION).filter(p => !p.hidden && (internal || !p.internal)).map(p => <HeaderMenuItem element={Link} to={p.path} key={p.path}>{p.label}</HeaderMenuItem>);
    }
}

export default NavigationHelper;